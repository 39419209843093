
// 画像アセット
export const ASSET = {
    NO_IMAGE: '/assets/images/no_image.png'
};

// 外部API
export const ZIP_API = {
    SEARCH: 'https://zipcloud.ibsnet.co.jp/api/search'
};

// 内部パス
export const PATH = {
    PHOTOGRAPHY_DETAIL: '/dashboard/photography/',
    SELECT_PHOTO: 'selectphoto/',
    SELECT_CONFIRM: '/select_confirm/',
    SELECT_DONE: '/select_done/',
};

export const COURSE_A = 'Aコース';
export const COURSE_B = 'Bコース';
export const COURSE_C = 'Cコース';
export const COURSE_D = 'Dコース';
export const COURSE_E = 'Eコース';
export const COURSE_N = 'Nコース';
export const COURSE_6P = '6Pコース';
export const COURSE_8P = '8Pコース';
export const COURSE_20P = '20Pコース';
export const COURSE_40P = '40Pコース';
export const COURSE_0POSE = '0ポーズ';
export const COURSE_1POSE = '1ポーズ';
export const COURSE_2POSE = '2ポーズ';
export const COURSE_3POSE = '3ポーズ';
export const COURSE_4POSE = '4ポーズ';
export const COURSE_5POSE = '5ポーズ';
export const COURSE_6POSE = '6ポーズ';
export const COURSE_7POSE = '7ポーズ';
export const COURSE_8POSE = '8ポーズ';
export const COURSE_9POSE = '9ポーズ';
export const COURSE_10POSE = '10ポーズ';
export const COURSE_2PAGE = '2面';
export const COURSE_3PAGE = '3面';
export const COURSE_5PAGE = '5面';
export const COURSE_20ALBUM = '20Pアルバム';

// 撮影タイプ
export const TYPE_MATANITY = 'MATERNITY';
export const TYPE_NEW_BORN = 'NEW_BORN';
export const TYPE_HYAKUNICHI = 'HYAKUNICHI';
export const TYPE_BIRTH = 'BIRTH';
export const TYPE_SHICHIGOSAN = 'SHICHIGOSAN';
export const TYPE_SCHOOL = 'SCHOOL';
export const TYPE_SEIJIN = 'SEIJIN';
export const TYPE_SEINEN = 'SEINEN';
export const TYPE_ALBUM = 'ALBUM';
export const TYPE_FAMIRY = 'FAMIRY';
export const TYPE_WEDDING = 'WEDDING';
export const TYPE_GROUP = 'GROUP';
export const TYPE_SIMPLE = 'SIMPLE';

export const PHOTOGRAPHY_INFO = {
    MATERNITY   : { name : 'マタニティ',
                    courses : [
                        { name : COURSE_A, counts : [1,2,1,0] },
                        { name : COURSE_B, counts : [1,2,2,0] },
                        { name : COURSE_C, counts : [1,3,4,0] }]
                  },
    NEW_BORN    : { name : 'ニューボーン',
                    courses : [
                        { name : COURSE_N, counts : [0,2,3,0] },
                        { name : COURSE_A, counts : [1,2,1,0] },
                        { name : COURSE_B, counts : [1,2,2,0] }]
                  },
    HYAKUNICHI  : { name : '百日',
                    courses : [
                        { name : COURSE_A, counts : [1,2,1,0] },
                        { name : COURSE_B, counts : [1,2,2,0] },
                        { name : COURSE_C, counts : [1,3,4,0] },
                        { name : COURSE_D, counts : [1,3,5,1] },
                        { name : COURSE_E, counts : [1,5,5,1] }]
                  },
    BIRTH       : { name : '誕生',
                    courses : [
                        { name : COURSE_A, counts : [1,2,1,0] },
                        { name : COURSE_B, counts : [1,2,2,0] },
                        { name : COURSE_C, counts : [1,3,4,0] },
                        { name : COURSE_D, counts : [1,3,5,1] },
                        { name : COURSE_E, counts : [1,5,5,1] },
                        { name : COURSE_N, counts : [0,1,2,0] }]
                  },
    SHICHIGOSAN : { name : '七五三',
                    courses : [
                        { name : COURSE_A, counts : [1,2,1,0] },
                        { name : COURSE_B, counts : [1,2,2,0] },
                        { name : COURSE_C, counts : [1,3,4,0] },
                        { name : COURSE_D, counts : [1,3,5,1] },
                        { name : COURSE_E, counts : [1,5,5,1] },
                        { name : COURSE_6P, counts : [1,6,7,0] },
                        { name : COURSE_8P, counts : [1,8,7,0] }]          
                  },
    JUUSAN      : { name : '十三祝',
                    courses : [
                        { name : COURSE_A, counts : [1,2,1,0] },
                        { name : COURSE_B, counts : [1,2,2,0] },
                        { name : COURSE_C, counts : [1,3,4,0] },
                        { name : COURSE_D, counts : [1,3,5,1] },
                        { name : COURSE_E, counts : [1,5,5,1] },
                        { name : COURSE_6P, counts : [1,6,7,0] },
                        { name : COURSE_8P, counts : [1,8,7,0] }]
                  },
    SCHOOL      : { name : '入学/卒業',
                    courses : [
                        { name : COURSE_A, counts : [1,2,1,0] },
                        { name : COURSE_B, counts : [1,2,2,0] },
                        { name : COURSE_C, counts : [1,3,4,0] },
                        { name : COURSE_D, counts : [1,3,5,1] },
                        { name : COURSE_E, counts : [1,5,5,1] }]
                  },
    SEIJIN      : { name : '成人祝',
                    courses : [
                        { name : COURSE_A, counts : [1,2,1,0] },
                        { name : COURSE_B, counts : [1,2,2,0] },
                        { name : COURSE_C, counts : [1,3,4,0] },
                        { name : COURSE_D, counts : [1,3,2,1] }]
                  },
    SEINEN      : { name : '生年祝',
                    courses : [
                        { name : COURSE_A, counts : [1,2,1,0] },
                        { name : COURSE_B, counts : [1,2,2,0] },
                        { name : COURSE_C, counts : [1,3,2,0] },
                        { name : COURSE_D, counts : [1,3,2,1] },
                        { name : COURSE_E, counts : [1,5,2,1] }]
                  },
    ALBUM       : { name : 'アルバム',
                    courses : [
                        { name : COURSE_20P, counts : [0,0,25,0] },
                        { name : COURSE_40P, counts : [0,0,50,0] }]
                  },
    FAMIRY      : { name : '家族',
                    courses : [
                        { name : COURSE_A, counts : [1,2,0,0] },
                        { name : COURSE_1POSE, counts : [0,1,0,0] },
                        { name : COURSE_2POSE, counts : [0,2,0,0] },
                        { name : COURSE_3POSE, counts : [0,3,0,0] }]
                  },
    WEDDING     : { name : '婚礼',
                    courses : [
                        { name : COURSE_2PAGE, counts : [1,2,1,0] },
                        { name : COURSE_3PAGE, counts : [1,3,4,0] },
                        { name : COURSE_5PAGE, counts : [1,5,6,1] },
                        { name : COURSE_6P, counts : [1,6,7,0] },
                        { name : COURSE_8P, counts : [1,8,7,0] },
                        { name : COURSE_20ALBUM, counts : [1,20,7,0] }]
                    },
    GROUP       : { name : 'グループフォト',
                    courses : [
                        { name : COURSE_1POSE, counts : [0,1,0,0] },
                        { name : COURSE_A, counts : [1,2,0,0] },
                        { name : COURSE_2POSE, counts : [0,2,0,0] },
                        { name : COURSE_B, counts : [1,2,0,1] }]
                  },
    SIMPLE      : { name : 'シンプルコース',
                    courses : [
                        { name : COURSE_0POSE, counts : [0,0,0,0] },
                        { name : COURSE_1POSE, counts : [0,1,0,0] },
                        { name : COURSE_2POSE, counts : [0,2,0,0] },
                        { name : COURSE_3POSE, counts : [0,3,0,0] },
                        { name : COURSE_4POSE, counts : [0,4,0,0] },
                        { name : COURSE_5POSE, counts : [0,5,0,0] },
                        { name : COURSE_6POSE, counts : [0,6,0,0] },
                        { name : COURSE_7POSE, counts : [0,7,0,0] },
                        { name : COURSE_8POSE, counts : [0,8,0,0] },
                        { name : COURSE_9POSE, counts : [0,9,0,0] },
                        { name : COURSE_10POSE, counts : [0,10,0,0] }]
                  }
};

export const SERVICE_INFO = {
    KEY_HOLDER  : { 
        index : 0,
        name : 'キーホルダー',
        count : 2
    },
    LL_FRAME    : {
        index : 1,
        name : '2L額付',
        count : 1
    },
    CALENDAR    : {
        index : 2,
        name : 'カレンダー',
        count : 1
    },
    WIDE_4CUT   : {
        index : 3,
        name : '大伸ばし',
        count : 1
    },
    KOTI        : {
        index : 4,
        name : 'コティ',
        count : 1
    }
}

export const SAMPLE_PRESENT_INFO = {
    PHOTO_L : { 
        index : 0,
        name : 'PHOTOデザインプリント',
        count : 1
    },
    CAN_BADGE : {
        index : 1,
        name : '缶バッジ',
        count : 1
    }
}

export const OPTION_INFO = {
    CRYSTAL_LAND : {
        index : 0,
        name : 'クリスタル写真プレート(横長)',
        count : 3,
        price : 4400,
        image_src : 'crystal_long.jpg'
    },
    CRYSTAL_SQUARE : {
        index : 1,
        name : 'クリスタル写真プレート(スクエア)',
        count : 3,
        price : 4400,
        image_src : 'crystal_square.jpg'
    },
    ACRYLIC     : {
        index : 2,
        name : 'アクリル写真立て(2L 2枚)',
        count : 2,
        price : 4400,
        image_src : 'acrylic_stand.jpg'
    },
    CALENDAR    : {
        index : 3,
        name : '写真入りカレンダー',
        count : 1,
        price : 2000,
        image_src : 'calendar.jpg'
    },
    KEY_HOLDER  : {
        index : 4,
        name : 'キーホルダー',
        count : 2,
        price : 1100,
        image_src : 'key_holder.jpg'
    },
    CAN_BADGE   : {
        index : 5,
        name : '缶バッチ',
        count : 1,
        price : 1100,
        image_src : 'can_badge.jpg'
    },
    KOTI      : {
        index : 6,
        name : 'コティ',
        count : 1,
        price : 8800,
        image_src : 'koti.jpg'
    },
    GALLERY_B  : {
        index : 7,
        name : 'ギャラリー(黒)',
        count : 1,
        price : 8800,
        image_src : 'gallery_black.jpg'
    },
    GALLERY_W  : {
        index : 8,
        name : 'ギャラリー(木地)',
        count : 1,
        price : 8800,
        image_src : 'gallery_wood.jpg'
    },
    LL_WHITE   : {
        index : 9,
        name : '2L額付(ホワイト)',
        count : 1,
        price : 3300,
        image_src : '2L_frame_white.jpg'
    },
    LL_WOOD   : {
        index : 10,
        name : '2L額付(木地)',
        count : 1,
        price : 3300,
        image_src : '2L_frame_wood.jpg'
    },
    ART_STYLE   : {
        index : 11,
        name : 'アートスタイル',
        count : 1,
        price : 8800,
        image_src : '01.jpg'
    },
    A3_PANEL    : {
        index : 12,
        name : 'A3パネル',
        count : 1,
        price : 11000,
        image_src : '01.jpg'
    },
    CANVAS_M    : { 
        index : 13,
        name : 'キャンバス(中)',
        count : 1,
        price : 6600,
        image_src : 'canvas_middle.jpg'
    },
    CANVAS_L    : {
        index : 14,
        name : 'キャンパス(大)',
        count : 1,
        price : 7150,
        image_src : 'canvas_big.jpg'
    },
    HYAKUNICHI_1 : {
        index : 15,
        name : '百日手形足形記念額-A',
        count : 1,
        price : 22550,
        image_src : 'hyakunichi_01.jpg'
    },
    HYAKUNICHI_2 : {
        index : 16,
        name : '百日手形足形記念額-B',
        count : 1,
        price : 16500,
        image_src : 'hyakunichi_02.jpg'
    },
    HYAKUNICHI_3 : {
        index : 17,
        name : '百日手形足形記念額-C',
        count : 1,
        price : 14300,
        image_src : 'hyakunichi_03.jpg'
    },
    ONEYEAR_MEMORIAL : {
        index : 18,
        name : '1歳特製記念額(A4)',
        count : 1,
        price : 3850,
        image_src : 'one_year_memorial.jpg'
    },
    LL_1 : {
        index : 19,
        name : '2L判-一面',
        count : 1,
        price : 3000,
        image_src : '2L_1.jpg'
    },
    LL_2 : {
        index : 20,
        name : '2L判-二面',
        count : 2,
        price : 5520,
        image_src : '2L_2.jpg'
    },
    LL_3 : {
        index : 21,
        name : '2L判-三面',
        count : 3,
        price : 8300,
        image_src : '2L_3.jpg'
    },
    MUTSU_1 : {
        index : 22,
        name : '六切-一面',
        count : 1,
        price : 4400,
        image_src : 'mutsugiri_1.jpg'
    },
    MUTSU_2 : {
        index : 23,
        name : '六切-二面',
        count : 2,
        price : 7600,
        image_src : 'mutsugiri_2.jpg'
    },
    MUTSU_3 : {
        index : 24,
        name : '六切-三面',
        count : 3,
        price : 10000,
        image_src : 'mutsugiri_3.jpg'
    },
}

export const COPY_INFO = {
    L_PRINT   : { 
        index: 0,
        name : 'L判',
        price : 1100,
        size : '89mm×127mm',
    },
    LL_PRINT : { 
        index: 1,
        name : '2L判',
        price : 2200,
        size : '127mm×178mm',
    },
    YATSUGIRI : { 
        index: 2,
        name : '八切',
        price : 2750,
        size : '165mm×216mm',
    },
    MUTSUGIRI : { 
        index: 3,
        name : '六切',
        price : 3300,
        size : '203mm×254mm',
    },
    YOTSUGIRI : { 
        index: 4,
        name : '四切',
        price : 4400,
        size : '254mm×305mm',
    },
    A3        : { 
        index: 5,
        name : 'A3',
        price : 5500,
        size : '297mm×420mm',
    },
    HANGIRI   : { 
        index: 6,
        name : '半切',
        price : 6600,
        size : '356mm×432mm',
    },
    FULL      : { 
        index: 7,
        name : '全紙',
        price : 7700,
        size : '457mm×560mm',
    },
}