import * as React from "react";
import PropTypes from "prop-types";
// mui
import { Card, CardActionArea, Stack, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import SpinButton from '../spinbutton';
// other
import { OPTION_INFO } from '../../constants';
import uuidjs from 'uuidjs';

export default function PhotographyOptionSelectDialog({showDialog, optionCountList, isDecideBtnEnable, onChange, onDecide, onSkip, onBack}) {

  const StyledProductImg = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "contain",
    position: "absolute"
  });

  React.useEffect(() => {
    Object.keys(OPTION_INFO).map((key) => {
        console.log();
        // optionCountList()
    });
  }, [])

  return (
    <div>
      <Dialog open={showDialog}>
        <DialogTitle>追加オプション</DialogTitle> 
        <DialogContent>
          <DialogContentText marginBottom={1}>追加したいオプションの数量を入力してください<br />※別途料金がかかります</DialogContentText>
          <Grid container spacing={2}>
            {Object.keys(OPTION_INFO).map((key) => (
              <Grid item xs={6} key={key}>
              <Card>
                <Typography variant='caption' marginTop={2}>{OPTION_INFO[key].name} </Typography>
                <Box sx={{ pt: "100%", position: "relative" }}>
                  <StyledProductImg src={`${process.env.PUBLIC_URL}/assets/images/options/` + OPTION_INFO[key].image_src} />                    
                </Box>
                <Typography variant='caption' marginTop={2}>{`¥${OPTION_INFO[key].price.toLocaleString()}`}</Typography>
                <Stack spacing={1} sx={{ p: 1 }}>
                  <SpinButton value={optionCountList[OPTION_INFO[key].index]} unit="個"
                    onChange= { (value) => {
                      onChange(value, key);
                    }
                  } />
                </Stack>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={3}>
              <Box my={2} sx={{ display:'flex', justifyContent:'flex-start', flexDirection:"row" }}>
                <Box ml={2}>
                <Button color="primary" variant="outlined" onClick={
                    (event) => {
                      onBack();
                    }
                  }>
                  戻る
                </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box my={2} sx={{ display:'flex', justifyContent:'flex-end', flexDirection:"row" }}>
                <Box>
                  <Button color="secondary" variant="outlined" onClick={
                    (event) => {
                      onSkip();
                    }
                  }>
                  スキップ
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button color="primary" variant="contained" disabled={!isDecideBtnEnable} onClick={
                    (event) => {
                      let optionSelectedResult = [];
                      // 何がどれだけ選択されたか通知する
                      optionCountList.forEach( (count, index)  => {
                        Object.keys(OPTION_INFO).map((key) => {
                          if (OPTION_INFO[key].index == index) {
                            for (let i = 0; count > i; i++) {
                              optionSelectedResult.push({type: key, id: uuidjs.generate()});
                            }
                          }
                        })
                      });
                      console.log(optionSelectedResult);

                      onDecide(optionSelectedResult);
                    }
                  }>
                  　決定　
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
